import {
    DiagramScreenshot,
    SettingsScreenshot,
    OnlineOfflineMobileScreenshot, DiagramMobileIcon, ComfortViewIcon, SettingsMobileIcon,
    AnalysePromotionIcon, SearchPhrasesIcon, TelegramNotifications
} from "../screenshots";
import React from 'react';
import {PrimaryButton} from "../components";
const PricesControlSection = () => {
    return (
        <div className="z-20 bg-[#02070D] relative">
            <div className="pt-[60px] lg:pt-[150px] px-5 lg:px-10 flex flex-col">
                <div className="flex flex-col lg:items-center pb-[50px] lg:pb-[100px]">
                    <span className="block text-[36px] leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#3E4349] font-medium -tracking-[2px] lg:-tracking-[3px]">
                        Прайс Ниндзя
                    </span>
                    <span className="block text-[36px] leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#FBFBFC] font-medium -tracking-[2px] lg:-tracking-[3px]">
                        разработан специально
                    </span>
                    <span className="block text-[36px] leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#FBFBFC] font-medium -tracking-[2px] lg:-tracking-[3px]">
                        для контроля цен<br className="hidden lg:block"/> на <br className="lg:hidden"/>маркетплейсах
                    </span>
                </div>
                <div className="hidden lg:flex space-x-5 self-center">
                    <div className="flex flex-col space-y-5">
                        <div className="flex flex-col items-center p-20 rounded-[60px] bg-[#1B2126] w-[589px]">
                            <span className="block text-2xl leading-[34px] text-white  font-medium">
                                Учитываем особенности
                            </span>
                            <span className="block text-2xl leading-[34px] text-white  font-medium">
                                маркетплейсов
                            </span>
                            <span className="block mt-10 text-lg text-[#919BA0] font-normal">
                                Фиксируем скидки по картам Ozon и ЯPay.
                            </span>
                            <span className="block text-lg text-[#919BA0] mb-20 font-normal">
                                Учитываем промокоды и кешбеки
                            </span>
                            <DiagramScreenshot />
                        </div>
                        <div className="flex flex-col items-center p-20 rounded-[60px] bg-[#1B2126] w-[589px]">
                            <span className="block text-2xl leading-[34px] text-white  font-medium">
                                Анализируем акции
                            </span>
                            <span className="block mt-10 text-center text-lg text-[#919BA0] font-normal">
                                Показываем, в каких акциях участвуют конкуренты
                            </span>
                            <div className="top-20 relative">
                                <AnalysePromotionIcon />
                            </div>
                        </div>
                        <div className="flex flex-col items-center p-20 rounded-[60px] bg-[#1B2126] w-[589px]">
                            <span className="block text-2xl leading-[34px] text-white  font-medium">
                                Контроль РРЦ
                            </span>
                            <span className="block mt-10 text-center text-lg text-[#919BA0] font-normal">
                                Покажем нарушение РРЦ и отправим уведомления
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col space-y-5">
                        <div className="flex flex-col items-center p-20 rounded-[60px] bg-[#1B2126] w-[589px]">
                            <span className="block text-center text-2xl leading-[34px] text-white  font-medium">
                                Уведомляем в Telegram или на почту
                            </span>
                            <span className="block mt-10 text-center text-lg text-[#919BA0] font-normal">
                                Присылаем уведомления об изменении цен и наличия по вашему графику
                            </span>
                            <div className="relative top-5">
                                <TelegramNotifications />
                            </div>
                        </div>
                        <div className="flex flex-col items-center p-20 rounded-[60px] bg-[#1B2126] w-[589px] flex-grow justify-between">
                            <div className={"flex flex-col items-center"}>
                                <span className="block text-2xl leading-[34px] text-white  font-medium">
                                    Рассчитываем unit экономику
                                </span>
                                <span className="block text-center mt-10 text-lg text-[#919BA0] font-normal">
                                     Считаем маржу и допустимую скидку на товар. Учитываем комиссии, логистику, рекламу, себестоимость и другие расходы
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-col items-center p-20 rounded-[60px] bg-[#1B2126] w-[589px] flex-grow justify-between">
                            <div className={"flex flex-col items-center"}>
                                <span className="block text-2xl leading-[34px] text-white  font-medium">
                                    Экспорт отчетов
                                </span>
                                <span className="block text-center mt-10 text-lg text-[#919BA0] font-normal">
                                    Сохраняйте данные в Excel
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-col items-center p-20 rounded-[60px] bg-[#1B2126] w-[589px] flex-grow justify-between">
                            <div className={"flex flex-col items-center"}>
                                <span className="block text-2xl leading-[34px] text-white  font-medium">
                                    История изменений
                                </span>
                                <span className="block text-center mt-10 text-lg text-[#919BA0] font-normal">
                                    Показываем, когда менялись цены и заканчивался товар
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="hidden mt-5 lg:flex space-x-5 self-center w-full max-w-[1198px]">
                    <div className="flex flex-col space-y-5 w-full">
                        <div className="flex justify-between items-center p-20 rounded-[60px] bg-[#1B2126] ">
                            <div className="flex flex-col ">
                                <span className="block text-2xl leading-[34px] text-white  font-medium">
                                    Проверяем поисковые запросы
                                </span>
                                <span className="block mt-10 text-lg text-[#919BA0] font-normal">
                                    Покажем позиции товаров на WB и OZ
                                </span>
                                <PrimaryButton className={'w-full mt-10 py-5 lg:w-fit lg:px-12 lg:py-6 flex items-center justify-center'} onClick={() => window.location.href = 'https://go.priceninja.ru/products'}>
                                    <span className="text-white font-semibold text-[18px] leading-[21px] lg:text-xl lg:leading-6">
                                        Попробовать бесплатно
                                    </span>
                                </PrimaryButton>
                            </div>
                            <div className="relative left-20">
                                <SearchPhrasesIcon />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="lg:hidden flex flex-col space-y-2">
                    <div className="py-5 px-6 rounded-[20px] bg-[#1B2126] w-full space-y-5">
                        <div className="flex items-center">
                            <div className={"flex grow text-[14px] leading-[19px] text-[#FBFBFC] font-medium"}>
                                Учитываем <br />особенности<br /> маркетплейсов
                            </div>
                            <div>
                                <DiagramMobileIcon />
                            </div>
                        </div>
                        <div className="text-[#919BA0] text-xs leading-[18px] font-normal pr-[80px]">
                            Следите за онлайн-ценами на ноутбуке или мобильном и легко сохраняйте данные в Excel или CSV.
                        </div>
                    </div>
                    <div className="py-5 px-6 rounded-[20px] bg-[#1B2126] w-full space-y-5">
                        <div className="flex items-center">
                            <div className={"flex grow text-[14px] leading-[19px] text-[#FBFBFC] font-medium"}>
                                Удобное <br />отображение<br /> данных
                            </div>
                            <div>
                                <ComfortViewIcon />
                            </div>
                        </div>
                        <div className="text-[#919BA0] text-xs leading-[18px] font-normal pr-[80px]">
                            Сравним цены с конкурентами и подсветим лучшие предложения. Смотрите динамику цен и наличие товаров на наглядных графиках, таблицах и дашбордах.
                        </div>
                    </div>
                    <div className="py-5 px-6 rounded-[20px] bg-[#1B2126] w-full space-y-5">
                        <div className="flex items-center">
                            <div className={"flex grow text-[14px] leading-[19px] text-[#FBFBFC] font-medium"}>
                                Настраивайте <br />данные в нужном<br />разрезе
                            </div>
                            <div>
                                <SettingsMobileIcon />
                            </div>
                        </div>
                        <div className="text-[#919BA0] text-xs leading-[18px] font-normal pr-[80px]">
                            Настройте фильтры и сравнивайте цены с учетом 9 различных параметров, включая способ оплаты, сроки доставки, и склад отгрузки.
                        </div>
                    </div>
                    <div className="py-5 px-6 rounded-[20px] bg-[#1B2126] w-full space-y-5">
                        <div className="flex items-center">
                            <div className={"flex grow text-[14px] leading-[19px] text-[#FBFBFC] font-medium"}>
                                Изучайте данные <br />онлайн или в <br />офлайн
                            </div>
                            <div>
                                <OnlineOfflineMobileScreenshot />
                            </div>
                        </div>
                        <div className="text-[#919BA0] text-xs leading-[18px] font-normal pr-[80px]">
                            Следите за онлайн-ценами на ноутбуке или мобильном и легко сохраняйте данные в Excel или CSV.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {PricesControlSection};
