import cn from "classnames";
import React from "react";

export const PricesMobileSearchPhrases = ({
     tariffs,
     idMonth,
     total,
     activeCount,
     setActiveCount,
     setActiveFreq,
     activePeriod,
     setActivePeriod,
     tariffPrice,
     priceMonth
 }) => {
    const activeTariff = tariffs.find((el) => el.count_products === activeCount);
    return (
        <div className="lg:hidden flex flex-col pl-6">
            <div className="flex items-center pr-6">
        <span className="block text-[#D9D9D9] text-xs leading-[18px] font-bold mr-4">
          ШАГ 1
        </span>
                <div className="grow bg-[#2B3135] h-[1px]" />
            </div>
            <span className="block mt-1 text-xs leading-[18px] text-[#919BA0]">
        Количество поисковых фраз
        <br />
         для мониторинга
      </span>
            <div className="relative">
                <div
                    className="absolute right-0 w-[35px] h-[70px]"
                    style={{
                        background:
                            "linear-gradient(267deg, #1B2126 16.37%, rgba(27, 33, 38, 0.00) 96.93%)",
                    }}
                />
                <div className="mt-3 overflow-x-auto mobile-tariffs-block">
                    <div className="flex space-x-2 pb-6">
                        {tariffs.map((tariff) => {
                            const { name, price, count_products } = tariff;
                            const resultName = name.split(" ");
                            if (Number.isInteger(+resultName[0])) {
                                resultName[0] = (+resultName[0]).toLocaleString("ru");
                            }
                            return (
                                <div
                                    className={cn(
                                        "border border-[rgb(255,255,255,0.07)] py-2 px-3 rounded-[8px]",
                                        {
                                            "border-white": count_products === activeCount,
                                        }
                                    )}
                                    key={count_products + name}
                                    onClick={() => {
                                        if (count_products === 10) {
                                            setActivePeriod(1);
                                            setActiveFreq(1);
                                        }
                                        setActiveCount(count_products);
                                    }}
                                >
                  <span className="block text-white text-[12px] leading-[15px] whitespace-nowrap">
                    {resultName.join(" ")}
                  </span>
                                    <span className="block text-[#FFE8AE] text-[10px] leading-[16px] pt-[3px]">
                    {price === -1
                        ? "Персонально"
                        : activeCount === count_products
                            ? price === 0
                                ? "Бесплатно"
                                : ""
                            : `${tariffPrice > price ? "-" : "+"} ${Math.abs(
                                price - tariffPrice
                            ).toLocaleString("ru")} ₽`}
                  </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className="flex items-center pr-6">
        <span className="block text-[#D9D9D9] text-xs leading-[18px] font-bold mr-4">
          ШАГ 2
        </span>
                <div className="grow bg-[#2B3135] h-[1px]" />
            </div>
            <span className="block mt-1 text-xs leading-[18px] text-[#919BA0]">
        Выберите кол-во месяцев <br /> действия сервиса
      </span>
            <div className="relative">
                <div
                    className="absolute right-0 w-[35px] h-[70px]"
                    style={{
                        background:
                            "linear-gradient(267deg, #1B2126 16.37%, rgba(27, 33, 38, 0.00) 96.93%)",
                    }}
                />
                <div className="mt-3 overflow-x-auto mobile-tariffs-block">
                    <div className="flex space-x-2 pb-6">
                        {activeTariff &&
                            activeTariff.periods.filter(
                                (el, index) => !(activeCount === 10 && index !== 0)
                            )
                                .map((period, index) => {
                                    const { name, id } = period;
                                    const splittedName = name.split(" ");
                                    splittedName[1] = splittedName[1].slice(0, 3);
                                    return (
                                        <div
                                            className={cn(
                                                "border border-[rgb(255,255,255,0.07)] py-2 px-3 rounded-[8px]",
                                                {
                                                    "border-white": id === activePeriod,
                                                }
                                            )}
                                            key={id + name}
                                            onClick={() => setActivePeriod(id)}
                                        >
                      <span className="block text-white text-[12px] leading-[15px] whitespace-nowrap">
                        {splittedName.join(" ")}
                      </span>
                                            {index !== 0 && (
                                                <span className="block text-[#FF7B74] text-[10px] leading-[16px] pt-[3px] whitespace-nowrap">{`Скидка ${
                                                    10 + (index - 1) * 5
                                                } %`}</span>
                                            )}
                                            {/*{index !== 0 && <span className="block text-[#FF7B74] text-[10px] leading-[16px]">{`${10 + (index - 1) * 5} %`}</span>}*/}
                                        </div>
                                    );
                                })}
                    </div>
                </div>
            </div>
            <div className="flex items-center pr-6">
        <span className="block text-[#D9D9D9] text-xs leading-[18px] font-bold mr-4">
          ШАГ 3
        </span>
                <div className="grow bg-[#2B3135] h-[1px]" />
            </div>
            <span className="block mt-1 text-xs leading-[18px] text-[#919BA0]">
        Осталось оплатить и начать
        <br /> пользоваться!
      </span>

            <div className="pr-6">
                              <span
                                  className={cn(
                                      'block mb-4 flex justify-between w-full items-baseline text-[#9DCF4E] text-[32px] font-normal leading-[47px]',
                                      {
                                          '-tracking-[2px]': activeCount === 1001,
                                          '!text-[32px]':
                                              (total && total.toLocaleString('ru').length > 5) ||
                                              (total && total >= 2000) ||
                                              activeCount === 1001
                                      }
                                  )}
                              >
                                  <span>{priceMonth.toLocaleString('ru')} ₽</span>
                                  <span
                                      className="dots grow h-[2px] bg-[repeating-linear-gradient(90deg,_#9DCF4E,_#9DCF4E_2px,_transparent_2px,_transparent_6px)]">
                                  </span>
                                  <span className="text-base">в месяц</span>
                              </span>
                <span
                    className={cn(
                        'block mb-4 text-[#9DCF4E] text-[20px] font-normal leading-[23px]',
                        {
                            '-tracking-[2px]': activeCount === 1001
                        }
                    )}
                >
                                  {activeCount === 1001 ?
                                      'Персонально'
                                      : total === 0 ?
                                          'Бесплатно'
                                          : <div className="flex justify-between items-baseline w-full">
                                              <span>{total.toLocaleString('ru')} ₽</span>
                                              <span
                                                  className="dots grow h-[2px] bg-[repeating-linear-gradient(90deg,_#9DCF4E,_#9DCF4E_2px,_transparent_2px,_transparent_6px)]">
                                              </span>
                                              <span className="text-base">за {idMonth} мес</span>
                                          </div>
                                  }
                              </span>
            </div>
        </div>
    );
};
