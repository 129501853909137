import {TariffRadio} from "../TariffRadio";
import cn from "classnames";
import {PrimaryButton} from "../PrimaryButton";
import React from "react";
import {PricesMobile} from "../../sections/PricesMobile";

export const CostList = ({
     search = false,
     freqPrice,
     tariffPrice,
     activePeriod,
     setActivePeriod,
     activeTariff,
     tariffs,
     tariff,
     freq,
     total,
     activeCount,
     activeFreq,
     setActiveCount,
     setActiveFreq
 }) => {
    return (
        <div
            id="tariffs"
            className="w-full max-w-[1200px] bg-[#1B2126] rounded-[20px] lg:rounded-[60px] py-5 lg:p-20 mt-[49px] lg:mt-5"
        >
            <div className="hidden lg:flex space-x-10 justify-between">
                <div className="flex flex-grow max-w-[230px] min-w-[230px]">
                    <div className="space-y-8 flex-grow">
                        <div className="rounded-[100px] pt-[18px] px-6 pb-4 border border-white/[12%] w-fit">
          <span className="block text-white text-sm leading-[16px] font-semibold">
            ШАГ 1
          </span>
                        </div>
                        <div>
          <span className="block text-white font-medium text-[20px] whitespace-nowrap leading-[34px] mb-3">
            Количество товаров
          </span>
                            <span className="block text-[#919BA0] font-normal text-[16px]">
            Выберите кол-во товаров
            <br />
            для мониторинга цен
          </span>
                        </div>
                        <div className="h-0.5 bg-white/[7%]" />
                        <div className="space-y-[17px]">
                            {tariffs.map((tariff) => {
                                const { name, price, count_products } = tariff;
                                const resultName = name.split(" ");
                                if (Number.isInteger(+resultName[0])) {
                                    resultName[0] = (+resultName[0]).toLocaleString("ru");
                                }
                                return (
                                    <div
                                        className="flex items-center"
                                        key={count_products + name}
                                    >
                                        <div
                                            className={"flex items-center cursor-pointer"}
                                            onClick={() => {
                                                if (count_products === 10) {
                                                    setActivePeriod(1);
                                                    setActiveFreq(1);
                                                }
                                                setActiveCount(count_products);
                                            }}
                                        >
                                            <TariffRadio
                                                checked={count_products === activeCount}
                                                onChange={() => {
                                                    if (count_products === 10) {
                                                        setActivePeriod(1);
                                                        setActiveFreq(1);
                                                    }
                                                    setActiveCount(count_products);
                                                }}
                                            />
                                            <span className="block text-white text-[14px] leading-[22px] w-[98px] min-w-[98px] pt-[3px] whitespace-nowrap">
                    {resultName.join(" ")}
                  </span>
                                        </div>
                                        <span className="block ml-3 text-[#FFE8AE] text-[12px] leading-[20px] pt-[3px]">
                  {price === -1
                      ? "Персонально"
                      : activeCount === count_products
                          ? price === 0
                              ? "Бесплатно"
                              : ""
                          : `${tariffPrice > price ? "-" : "+"} ${Math.abs(
                              price - tariffPrice
                          ).toLocaleString("ru")} ₽`}
                </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                {!search && <div className="flex flex-grow max-w-[230px] min-w-[230px]">
                    <div className="space-y-8 flex-grow">
                        <div className="rounded-[100px] pt-[18px] px-6 pb-4 border border-white/[12%] w-fit">
                              <span className="block text-white text-sm leading-[16px] font-semibold">
                                ШАГ 2
                              </span>
                                            </div>
                                            <div>
                              <span className="block text-white font-medium text-[20px] whitespace-nowrap leading-[34px] mb-3">
                                Частота обновления
                              </span>
                                                <span className="block text-[#919BA0] font-normal text-[16px]">
                                Как часто необходимо
                                <br />
                                обновлять цены
                              </span>
                        </div>
                        <div className="h-0.5 bg-white/[7%]" />
                        <div className="space-y-[17px]">
                            {activeTariff &&
                                activeTariff.frequencies.map((freq) => {
                                    const { name, price, frequency } = freq;
                                    return (
                                        <div
                                            className="flex items-center"
                                            key={frequency + name}
                                        >
                                            <div
                                                className={"flex items-center cursor-pointer"}
                                                onClick={() => setActiveFreq(frequency)}
                                            >
                                                <TariffRadio
                                                    checked={frequency === activeFreq}
                                                    onChange={() => setActiveFreq(frequency)}
                                                />
                                                <span className="block text-white text-[14px] leading-[22px] w-[110px] min-w-[110px] pt-[3px] whitespace-nowrap">
                      {name}
                    </span>
                                            </div>
                                            <span className="block ml-3 text-[#FFE8AE] text-[12px] leading-[20px] pt-[3px]">
                    {price === -1
                        ? "Персонально"
                        : activeFreq === frequency
                            ? price === 0
                                ? "Бесплатно"
                                : ""
                            : `${freqPrice > price ? "-" : "+"} ${Math.abs(
                                price - freqPrice
                            ).toLocaleString("ru")} ₽`}
                  </span>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div> }
                <div className="flex flex-grow max-w-[230px] min-w-[230px]">
                    <div className="space-y-8 flex-grow">
                        <div className="rounded-[100px] pt-[18px] px-6 pb-4 border border-white/[12%] w-fit">
          <span className="block text-white text-sm leading-[16px] font-semibold">
            ШАГ 3
          </span>
                        </div>
                        <div>
          <span className="block text-white font-medium text-[20px] whitespace-nowrap leading-[34px] mb-3">
            Период действия
          </span>
                            <span className="block text-[#919BA0] font-normal text-[16px]">
            Выберите кол-во месяцев
            <br />
            действия сервиса
          </span>
                        </div>
                        <div className="h-0.5 bg-white/[7%]" />
                        <div className="space-y-[17px]">
                            {activeTariff &&
                                activeTariff.frequencies.find(
                                    (el) => el.frequency === activeFreq
                                ) &&
                                activeTariff.frequencies
                                    .find((el) => el.frequency === activeFreq)
                                    .periods.filter(
                                    (el, index) => !(activeCount === 10 && index !== 0)
                                )
                                    .map((period, index) => {
                                        const { name, id } = period;
                                        return (
                                            <div className="flex items-center" key={id + name}>
                                                <div
                                                    className={"flex items-center cursor-pointer"}
                                                    onClick={() => setActivePeriod(id)}
                                                >
                                                    <TariffRadio
                                                        checked={id === activePeriod}
                                                        onChange={() => setActivePeriod(id)}
                                                    />
                                                    <span className="block text-white text-[14px] leading-[22px] w-[85px] min-w-[85px] pt-[3px] whitespace-nowrap">
                        {name}
                      </span>
                                                </div>
                                                {index !== 0 && (
                                                    <span className="block ml-3 text-white text-[12px] px-1 leading-[22px] mt-[3px] rounded-[7px] bg-[#C93F38] font-semibold">{`- ${
                                                        10 + (index - 1) * 5
                                                    } %`}</span>
                                                )}
                                            </div>
                                        );
                                    })}
                        </div>
                    </div>
                </div>
                <div className="flex flex-grow max-w-[230px] min-w-[230px]">
                    <div className="space-y-8 flex-grow">
                        <div className="rounded-[100px] pt-[18px] px-6 pb-4 border border-white/[12%] w-fit">
          <span className="block text-white text-sm leading-[16px] font-semibold">
            ШАГ 4
          </span>
                        </div>
                        <div>
          <span className="block text-white font-medium text-[20px] leading-[34px] mb-3">
            Стоимость
          </span>
                            <span className="block text-[#919BA0] font-normal text-[16px]">
            Складывается из кол-ва
            <br />
            товаров и обновления цен
          </span>
                        </div>
                        <div className="h-0.5 bg-white/[7%]" />
                        <div>
          <span
              className={cn(
                  "block mb-4 text-[#9DCF4E] text-[39px] font-normal leading-[47px]",
                  {
                      "-tracking-[2px]": activeCount === 1001,
                      "!text-[32px]":
                          (total && total.toLocaleString("ru").length > 5) ||
                          (total && total >= 2000) ||
                          activeCount === 1001,
                  }
              )}
          >
            {activeCount === 1001
                ? "Персонально"
                : total === 0
                    ? "Бесплатно"
                    : `${
                        total && (total / activePeriod).toLocaleString("ru")
                    } ₽/мес`}
          </span>
                            <div className="py-2 px-3 border border-[rgb(157,207,78,0.50)] rounded-lg bg-[rgb(157,207,78,0.04)] w-fit">
            <span className="block text-[#9DCF4E] text-sm leading-[18px] font-normal">
              {activeCount === 1001 ? (
                  <span>
                  Мы свяжемся с вами, обсудим ваш запрос
                  <br />и предложим индивидуальный тариф.
                </span>
              ) : (
                  <span>
                  Мониторинг цен для <br />
                      {activeCount && activeCount.toLocaleString("ru")}{" "}
                      товаров {freq.name && freq.name.toLowerCase()}
                </span>
              )}
            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PricesMobile
                freqPrice={freqPrice}
                tariffPrice={tariffPrice}
                activePeriod={activePeriod}
                setActivePeriod={setActivePeriod}
                tariffs={tariffs}
                tariff={tariff}
                freq={freq}
                total={total}
                activeCount={activeCount}
                activeFreq={activeFreq}
                setActiveCount={setActiveCount}
                setActiveFreq={setActiveFreq}
            />
            <div className="px-6 lg:px-0 flex items-center justify-center">
                <PrimaryButton
                    className="w-full py-5 lg:py-0 mt-6 lg:mt-20 lg:h-[188px]"
                    onClick={() =>
                        (window.location.href = "https://go.priceninja.ru/products")
                    }
                >
                  <span className="block font-semibold lg:font-medium text-[18px] leading-[21px] lg:text-[60px] lg:leading-[124px] lg:-tracking-[3px] text-center text-white">
                    Мне все нравится,
                    <br className="lg:hidden" /> начинаем!
                  </span>
                </PrimaryButton>
            </div>
        </div>
    )
}
