import cn from "classnames";
import React from "react";

export const PricesMobile = ({
     tariffs,
     freq,
     total,
     activeCount,
     activeFreq,
     setActiveCount,
     setActiveFreq,
     activePeriod,
     setActivePeriod,
     tariffPrice,
     freqPrice,
 }) => {
    const activeTariff = tariffs.find((el) => el.count_products === activeCount);
    return (
        <div className="lg:hidden flex flex-col pl-6">
            <div className="flex items-center pr-6">
        <span className="block text-[#D9D9D9] text-xs leading-[18px] font-bold mr-4">
          ШАГ 1
        </span>
                <div className="grow bg-[#2B3135] h-[1px]" />
            </div>
            <span className="block mt-1 text-xs leading-[18px] text-[#919BA0]">
        Выберите кол-во товаров
        <br />
        для мониторинга цен
      </span>
            <div className="relative">
                <div
                    className="absolute right-0 w-[35px] h-[70px]"
                    style={{
                        background:
                            "linear-gradient(267deg, #1B2126 16.37%, rgba(27, 33, 38, 0.00) 96.93%)",
                    }}
                />
                <div className="mt-3 overflow-x-auto mobile-tariffs-block">
                    <div className="flex space-x-2 pb-6">
                        {tariffs.map((tariff) => {
                            const { name, price, count_products } = tariff;
                            const resultName = name.split(" ");
                            if (Number.isInteger(+resultName[0])) {
                                resultName[0] = (+resultName[0]).toLocaleString("ru");
                            }
                            return (
                                <div
                                    className={cn(
                                        "border border-[rgb(255,255,255,0.07)] py-2 px-3 rounded-[8px]",
                                        {
                                            "border-white": count_products === activeCount,
                                        }
                                    )}
                                    key={count_products + name}
                                    onClick={() => {
                                        if (count_products === 10) {
                                            setActivePeriod(1);
                                            setActiveFreq(1);
                                        }
                                        setActiveCount(count_products);
                                    }}
                                >
                  <span className="block text-white text-[12px] leading-[15px] whitespace-nowrap">
                    {resultName.join(" ")}
                  </span>
                                    <span className="block text-[#FFE8AE] text-[10px] leading-[16px] pt-[3px]">
                    {price === -1
                        ? "Персонально"
                        : activeCount === count_products
                            ? price === 0
                                ? "Бесплатно"
                                : ""
                            : `${tariffPrice > price ? "-" : "+"} ${Math.abs(
                                price - tariffPrice
                            ).toLocaleString("ru")} ₽`}
                  </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="flex items-center pr-6">
        <span className="block text-[#D9D9D9] text-xs leading-[18px] font-bold mr-4">
          ШАГ 2
        </span>
                <div className="grow bg-[#2B3135] h-[1px]" />
            </div>
            <span className="block mt-1 text-xs leading-[18px] text-[#919BA0]">
        Выберите как часто необходимо <br /> обновлять цены
      </span>
            <div className="relative">
                <div
                    className="absolute right-0 w-[35px] h-[70px]"
                    style={{
                        background:
                            "linear-gradient(267deg, #1B2126 16.37%, rgba(27, 33, 38, 0.00) 96.93%)",
                    }}
                />
                <div className="mt-3 overflow-x-auto mobile-tariffs-block">
                    <div className="flex space-x-2 pb-6">
                        {activeTariff &&
                            activeTariff.frequencies.map((freq) => {
                                const { name, price, frequency } = freq;
                                return (
                                    <div
                                        className={cn(
                                            "border border-[rgb(255,255,255,0.07)] py-2 px-3 rounded-[8px]",
                                            {
                                                "border-white": frequency === activeFreq,
                                            }
                                        )}
                                        key={frequency + name}
                                        onClick={() => setActiveFreq(frequency)}
                                    >
                    <span className="block text-white text-[12px] leading-[15px] whitespace-nowrap">
                      {name}
                    </span>
                                        <span className="block text-[#FFE8AE] text-[10px] leading-[16px] pt-[3px]">
                      {price === -1
                          ? "Персонально"
                          : activeFreq === frequency
                              ? price === 0
                                  ? "Бесплатно"
                                  : ""
                              : `${freqPrice > price ? "-" : "+"} ${Math.abs(
                                  price - freqPrice
                              ).toLocaleString("ru")} ₽`}
                    </span>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
            <div className="flex items-center pr-6">
        <span className="block text-[#D9D9D9] text-xs leading-[18px] font-bold mr-4">
          ШАГ 3
        </span>
                <div className="grow bg-[#2B3135] h-[1px]" />
            </div>
            <span className="block mt-1 text-xs leading-[18px] text-[#919BA0]">
        Выберите кол-во месяцев <br /> действия сервиса
      </span>
            <div className="relative">
                <div
                    className="absolute right-0 w-[35px] h-[70px]"
                    style={{
                        background:
                            "linear-gradient(267deg, #1B2126 16.37%, rgba(27, 33, 38, 0.00) 96.93%)",
                    }}
                />
                <div className="mt-3 overflow-x-auto mobile-tariffs-block">
                    <div className="flex space-x-2 pb-6">
                        {activeTariff &&
                            activeTariff.frequencies.find(
                                (el) => el.frequency === activeFreq
                            ) &&
                            activeTariff.frequencies
                                .find((el) => el.frequency === activeFreq)
                                .periods.filter(
                                (el, index) => !(activeCount === 10 && index !== 0)
                            )
                                .map((period, index) => {
                                    const { name, id } = period;
                                    const splittedName = name.split(" ");
                                    splittedName[1] = splittedName[1].slice(0, 3);
                                    return (
                                        <div
                                            className={cn(
                                                "border border-[rgb(255,255,255,0.07)] py-2 px-3 rounded-[8px]",
                                                {
                                                    "border-white": id === activePeriod,
                                                }
                                            )}
                                            key={id + name}
                                            onClick={() => setActivePeriod(id)}
                                        >
                      <span className="block text-white text-[12px] leading-[15px] whitespace-nowrap">
                        {splittedName.join(" ")}
                      </span>
                                            {index !== 0 && (
                                                <span className="block text-[#FF7B74] text-[10px] leading-[16px] pt-[3px] whitespace-nowrap">{`Скидка ${
                                                    10 + (index - 1) * 5
                                                } %`}</span>
                                            )}
                                            {/*{index !== 0 && <span className="block text-[#FF7B74] text-[10px] leading-[16px]">{`${10 + (index - 1) * 5} %`}</span>}*/}
                                        </div>
                                    );
                                })}
                    </div>
                </div>
            </div>
            <div className="flex items-center pr-6">
        <span className="block text-[#D9D9D9] text-xs leading-[18px] font-bold mr-4">
          ШАГ 4
        </span>
                <div className="grow bg-[#2B3135] h-[1px]" />
            </div>
            <span className="block mt-1 text-xs leading-[18px] text-[#919BA0]">
        Осталось оплатить и начать
        <br /> пользоваться!
      </span>
            <div className="mt-3 pr-6">
        <span
            className={cn(
                "block mb-2 text-[#9DCF4E] text-[24px] font-normal leading-[30px]"
            )}
        >
          {activeCount === 1001
              ? "Индивидуально"
              : total === 0
                  ? "Бесплатно"
                  : `${total && total / activePeriod} ₽/мес`}
        </span>
                <div className="py-2 px-3 border border-[rgb(157,207,78,0.50)] rounded-lg bg-[rgb(157,207,78,0.04)] w-full">
          <span className="block text-[#9DCF4E] text-[10px] leading-[14px] font-normal">
            {activeCount === 1001 ? (
                <span>
                Мы свяжемся с вами, обсудим
                <br />
                ваш запрос и предложим
                <br />
                индивидуальный тариф.
                    {/*Мы свяжемся с вами , обсудим<br/>ваш запрос и рассчитаем тариф<br/>индивидуально для вас*/}
              </span>
            ) : (
                <span>
                Мониторинг цен для{" "}
                    {activeCount && activeCount.toLocaleString("ru")} товаров{" "}
                    {freq.name && freq.name.toLowerCase()}
              </span>
            )}
          </span>
                </div>
            </div>
        </div>
    );
};
