import {TariffRadio} from "../TariffRadio";
import cn from "classnames";
import {PrimaryButton} from "../PrimaryButton";
import React, {useEffect, useState} from "react";
import {PricesMobileSearchPhrases} from "../../sections/PricesMobileSearchPhrases";

export const ConstListSearchTariff = () => {
    const [tariffs, setTariffs] = useState([]);
    const [activeCount, setActiveCount] = useState();
    const [activeFreq, setActiveFreq] = useState();
    const [activePeriod, setActivePeriod] = useState();
    const loadTariffs = async () => {
        const response = await fetch("https://back.priceninja.ru/api/tariffs");
        const data = await response.json();
        setTariffs([...data.search_tariffs]);
        if (data && data.search_tariffs) {
            setActiveCount(data.search_tariffs[0].count_products);
            setActivePeriod(data.search_tariffs[0].periods[0].id);
        }
    };
    useEffect(() => {
        loadTariffs();
    }, []);

    const tariff = tariffs.find(
        ({ count_products }) => count_products === activeCount
    );
    const tariffPrice =
        (tariff && tariff.price) === -1 ? 0 : tariff && tariff.price;
    const searched =
        tariff && tariff.periods && tariff.periods.find((el) => el.id === activePeriod);
    const total = (searched && searched.price_total) || 0;
    const priceMonth = (searched && searched.price_month) || 0;
    const idMonth = (searched && searched.id) || 0;
    const activeTariff = tariffs.find((el) => el.count_products === activeCount);

    return (
        <div
            id="tariffs"
            className="w-full max-w-[1200px] bg-[#1B2126] rounded-[20px] lg:rounded-[60px] py-5 lg:p-20 mt-[49px] lg:mt-5"
        >
            <div className="hidden lg:flex space-x-10 justify-between">
                <div className="flex flex-grow max-w-[230px] min-w-[230px]">
                    <div className="space-y-8 flex-grow">
                        <div className="rounded-[100px] pt-[18px] px-6 pb-4 border border-white/[12%] w-fit">
          <span className="block text-white text-sm leading-[16px] font-semibold">
            ШАГ 1
          </span>
                        </div>
                        <div>
          <span className="block text-white font-medium text-[20px] whitespace-nowrap leading-[34px] mb-3">
            Количество фраз
          </span>
                            <span className="block text-[#919BA0] font-normal text-[16px]">
            Количество поисковых фраз
            <br/>
            для мониторинга
          </span>
                        </div>
                        <div className="h-0.5 bg-white/[7%]"/>
                        <div className="space-y-[17px]">
                            {tariffs.sort((a, b) => a.count_products > b.count_products ? 1 : -1).map((tariff) => {
                                const {name, price, count_products} = tariff;
                                const resultName = name.split(" ");
                                if (Number.isInteger(+resultName[0])) {
                                    resultName[0] = (+resultName[0]).toLocaleString("ru");
                                }
                                return (
                                    <div
                                        className="flex items-center"
                                        key={count_products + name}
                                    >
                                        <div
                                            className={"flex items-center cursor-pointer"}
                                            onClick={() => {
                                                if (count_products === 10) {
                                                    setActivePeriod(1);
                                                    setActiveFreq(1);
                                                }
                                                setActiveCount(count_products);
                                            }}
                                        >
                                            <TariffRadio
                                                checked={count_products === activeCount}
                                                onChange={() => {
                                                    if (count_products === 10) {
                                                        setActivePeriod(1);
                                                        setActiveFreq(1);
                                                    }
                                                    setActiveCount(count_products);
                                                }}
                                            />
                                            <span
                                                className="block text-white text-[14px] leading-[22px] w-[98px] min-w-[98px] pt-[3px] whitespace-nowrap">
                    {resultName.join(" ")}
                  </span>
                                        </div>
                                        <span className="block ml-3 text-[#FFE8AE] text-[12px] leading-[20px] pt-[3px]">
                  {price === -1
                      ? "Персонально"
                      : activeCount === count_products
                          ? price === 0
                              ? "Бесплатно"
                              : ""
                          : `${tariffPrice > price ? "-" : "+"} ${Math.abs(
                              price - tariffPrice
                          ).toLocaleString("ru")} ₽`}
                </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div className="flex flex-grow max-w-[230px] min-w-[230px]">
                    <div className="space-y-8 flex-grow">
                        <div className="rounded-[100px] pt-[18px] px-6 pb-4 border border-white/[12%] w-fit">
          <span className="block text-white text-sm leading-[16px] font-semibold">
            ШАГ 2
          </span>
                        </div>
                        <div>
          <span className="block text-white font-medium text-[20px] whitespace-nowrap leading-[34px] mb-3">
            Период действия
          </span>
                            <span className="block text-[#919BA0] font-normal text-[16px]">
            Выберите кол-во месяцев
            <br/>
            действия сервиса
          </span>
                        </div>
                        <div className="h-0.5 bg-white/[7%]"/>
                        <div className="space-y-[17px]">
                            {activeTariff &&
                                activeTariff.periods.filter(
                                    (el, index) => !(activeCount === 10 && index !== 0)
                                )
                                    .map((period, index) => {
                                        const {name, id} = period;
                                        return (
                                            <div className="flex items-center" key={id + name}>
                                                <div
                                                    className={"flex items-center cursor-pointer"}
                                                    onClick={() => setActivePeriod(id)}
                                                >
                                                    <TariffRadio
                                                        checked={id === activePeriod}
                                                        onChange={() => setActivePeriod(id)}
                                                    />
                                                    <span
                                                        className="block text-white text-[14px] leading-[22px] w-[85px] min-w-[85px] pt-[3px] whitespace-nowrap">
                        {name}
                      </span>
                                                </div>
                                                {index !== 0 && (
                                                    <span
                                                        className="block ml-3 text-white text-[12px] px-1 leading-[22px] mt-[3px] rounded-[7px] bg-[#C93F38] font-semibold">{`- ${
                                                        10 + (index - 1) * 5
                                                    } %`}</span>
                                                )}
                                            </div>
                                        );
                                    })}
                        </div>
                    </div>
                </div>
                <div className="flex flex-grow max-w-[230px] min-w-[230px]">
                    <div className="space-y-8 flex-grow">
                        <div className="rounded-[100px] pt-[18px] px-6 pb-4 border border-white/[12%] w-fit">
          <span className="block text-white text-sm leading-[16px] font-semibold">
            ШАГ 3
          </span>
                        </div>
                        <div>
          <span className="block text-white font-medium text-[20px] leading-[34px] mb-[91px]">
            Стоимость
          </span>

                        </div>

                        <div className="h-0.5 bg-white/[7%]"/>

                          <div>
                              <span
                                  className={cn(
                                      'block mb-4 flex justify-between w-full items-baseline text-[#9DCF4E] text-[32px] font-normal leading-[47px]',
                                      {
                                          '-tracking-[2px]': activeCount === 1001,
                                          '!text-[32px]':
                                              (total && total.toLocaleString('ru').length > 5) ||
                                              (total && total >= 2000) ||
                                              activeCount === 1001
                                      }
                                  )}
                              >
                                  <span>{priceMonth.toLocaleString('ru')} ₽</span>
                                  <span
                                      className="dots grow h-[2px] bg-[repeating-linear-gradient(90deg,_#9DCF4E,_#9DCF4E_2px,_transparent_2px,_transparent_6px)]">
                                  </span>
                                  <span className="text-base">в месяц</span>
                              </span>
                              <span
                                  className={cn(
                                      'block mb-4 text-[#9DCF4E] text-[20px] font-normal leading-[23px]',
                                      {
                                          '-tracking-[2px]': activeCount === 1001
                                      }
                                  )}
                              >
                                  {activeCount === 1001 ?
                                      'Персонально'
                                      : total === 0 ?
                                          'Бесплатно'
                                          : <div className="flex justify-between items-baseline w-full">
                                              <span>{total.toLocaleString('ru')} ₽</span>
                                              <span
                                                  className="dots grow h-[2px] bg-[repeating-linear-gradient(90deg,_#9DCF4E,_#9DCF4E_2px,_transparent_2px,_transparent_6px)]">
                                              </span>
                                              <span className="text-base">за {idMonth} мес</span>
                                            </div>
                                  }
                              </span>
                          </div>
                    </div>
                </div>
            </div>
            <PricesMobileSearchPhrases
                tariffPrice={tariffPrice}
                activePeriod={activePeriod}
                setActivePeriod={setActivePeriod}
                tariffs={tariffs}
                tariff={tariff}
                idMonth={idMonth}
                priceMonth={priceMonth}
                total={total}
                activeCount={activeCount}
                activeFreq={activeFreq}
                setActiveCount={setActiveCount}
                setActiveFreq={setActiveFreq}
            />
            <div className="px-6 lg:px-0 flex items-center justify-center">
                <PrimaryButton
                    className="w-full py-5 lg:py-0 mt-6 lg:mt-20 lg:h-[188px]"
                    onClick={() =>
                        (window.location.href = "https://go.priceninja.ru/products")
                    }
                >
                  <span
                      className="block font-semibold lg:font-medium text-[18px] leading-[21px] lg:text-[60px] lg:leading-[124px] lg:-tracking-[3px] text-center text-white">
                    Мне все нравится,
                    <br className="lg:hidden"/> начинаем!
                  </span>
                </PrimaryButton>
            </div>
        </div>
    )
}
