import { useEffect, useState } from "react";
import { PrimaryButton, TariffRadio } from "../components";
import cn from "classnames";
import React from "react";
import {CostList} from "../components/CostList/CostList";
import {ConstListSearchTariff} from "../components/CostList/ConstListSearchTariff";

const TABS = [
  {
    id: 1,
    name: "Мониторинг цен"
  },
  {
    id: 2,
    name: "Поисковые фразы"
  },
]

const CostSection = () => {
  const [tariffs, setTariffs] = useState([]);
  const [activeCount, setActiveCount] = useState();
  const [activeFreq, setActiveFreq] = useState();
  const [activePeriod, setActivePeriod] = useState();
  const [activeTab, setActiveTab] = useState(TABS[0])
  const loadTariffs = async () => {
    const response = await fetch("https://back.priceninja.ru/api/tariffs");
    const data = await response.json();
    setTariffs(
      data && data.data
        ? [
            ...data.data,
            {
              id: 99,
              name: "Свыше 15 000",
              count_products: 1001,
              price: -1,
              frequencies: [
                {
                  id: 98,
                  name: "1 раз в сутки",
                  frequency: 1,
                  price: -1,
                  periods: [
                    {
                      id: 1,
                      name: "1 месяц",
                      price_total: -1,
                    },
                    {
                      id: 3,
                      name: "3 месяцa",
                      price_total: -1,
                    },
                    {
                      id: 6,
                      name: "6 месяцев",
                      price_total: -1,
                    },
                    {
                      id: 12,
                      name: "12 месяцев",
                      price_total: -1,
                    },
                  ],
                },
                {
                  id: 97,
                  name: "4 раза в сутки",
                  frequency: 6,
                  price: -1,
                  periods: [
                    {
                      id: 1,
                      name: "1 месяц",
                      price_total: -1,
                    },
                    {
                      id: 3,
                      name: "3 месяцa",
                      price_total: -1,
                    },
                    {
                      id: 6,
                      name: "6 месяцев",
                      price_total: -1,
                    },
                    {
                      id: 12,
                      name: "12 месяцев",
                      price_total: -1,
                    },
                  ],
                },
                {
                  id: 96,
                  name: "24 раза в сутки",
                  frequency: 24,
                  price: -1,
                  periods: [
                    {
                      id: 1,
                      name: "1 месяц",
                      price_total: -1,
                    },
                    {
                      id: 3,
                      name: "3 месяцa",
                      price_total: -1,
                    },
                    {
                      id: 6,
                      name: "6 месяцев",
                      price_total: -1,
                    },
                    {
                      id: 12,
                      name: "12 месяцев",
                      price_total: -1,
                    },
                  ],
                },
              ],
            },
          ]
        : []
    );
    if (data && data.data) {
      setActiveCount(data.data[0].count_products);
      setActiveFreq(data.data[0].frequencies[0].frequency);
      setActivePeriod(data.data[0].frequencies[0].periods[0].id);
    }
  };
  useEffect(() => {
    loadTariffs();
  }, []);
  const tariff = tariffs.find(
    ({ count_products }) => count_products === activeCount
  );
  const tariffPrice =
    (tariff && tariff.price) === -1 ? 0 : tariff && tariff.price;
  const freq = (tariff &&
    tariff.frequencies &&
    tariff.frequencies.find(({ frequency }) => frequency === activeFreq)) || {
    price: 0,
  };
  const freqPrice = freq && freq.price;
  const searched =
    freq && freq.periods && freq.periods.find((el) => el.id === activePeriod);
  const total = (searched && searched.price_total) || 0;
  const activeTariff = tariffs.find((el) => el.count_products === activeCount);

  const handleChange = (tab) => {
    setActiveTab(tab)
  }

  return (
      <div className={"bg-[#02070D] px-5 lg:px-0"}>
        <div className="pt-[100px] lg:pt-[150px] flex flex-col lg:items-center">
          <div className="flex flex-col lg:items-center">
              <span className="block text-[36px] leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#FBFBFC] font-medium -tracking-[2px] lg:-tracking-[3px]">
                Стоимость
              </span>
              <span className="block text-[36px] leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#3E4349] font-medium -tracking-[2px] lg:-tracking-[3px]">
                Прайс Ниндзя
              </span>
          </div>

          <div className="items-center flex mt-20 gap-3">
            {TABS.map((tab, index) => {
                return (
                    <div
                        key={tab.id}
                        className={activeTab.id === index+1 ? "rounded-[50px] border text-white border-indigo-60 py-5 px-6" : "cursor-pointer text-white py-5 px-6" }
                        onClick={() => handleChange(TABS[index])}
                    >
                      {tab.name}
                    </div>
                )
              })
            }
          </div>

          {activeTab === TABS[0] ? (
              <CostList
                  activeTariff={activeTariff}
                  freqPrice={freqPrice}
                  tariffPrice={tariffPrice}
                  activePeriod={activePeriod}
                  setActivePeriod={setActivePeriod}
                  tariffs={tariffs}
                  tariff={tariff}
                  freq={freq}
                  total={total}
                  activeCount={activeCount}
                  activeFreq={activeFreq}
                  setActiveCount={setActiveCount}
                  setActiveFreq={setActiveFreq}
              />
          ) : (
              <ConstListSearchTariff
                activeTariff={activeTariff}
                freqPrice={freqPrice}
                tariffPrice={tariffPrice}
                activePeriod={activePeriod}
                setActivePeriod={setActivePeriod}
                tariffs={tariffs}
                tariff={tariff}
                freq={freq}
                total={total}
                activeCount={activeCount}
                activeFreq={activeFreq}
                setActiveCount={setActiveCount}
                setActiveFreq={setActiveFreq}
              />
          )}
        </div>
      </div>
  );
};

export { CostSection };
