import React from 'react';
const SearchPhrasesIcon = () => {
    return (
        <svg width="482" height="237" viewBox="0 0 482 237" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M173.975 154.08C173.975 108.485 210.937 71.5234 256.532 71.5234H499.92C545.515 71.5234 582.477 108.485 582.477 154.08C582.477 199.676 545.515 236.638 499.92 236.638H256.532C210.937 236.638 173.975 199.676 173.975 154.08Z" fill="#6E7C88"/>
            <rect x="83.7656" y="36.1094" width="588.922" height="165.114" rx="82.5571" fill="#7C8A96"/>
            <rect x="0.115234" y="0.0527344" width="756.223" height="165.114" rx="82.5571" fill="#E7E7E7"/>
            <path d="M110.538 106.977L126.15 122.589M120.946 80.9565C120.946 101.075 104.637 117.385 84.518 117.385C64.3993 117.385 48.0898 101.075 48.0898 80.9565C48.0898 60.8378 64.3993 44.5283 84.518 44.5283C104.637 44.5283 120.946 60.8378 120.946 80.9565Z" stroke="#6F6F8D" strokeWidth="10.408" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export {SearchPhrasesIcon}
